import React from 'react'
import * as Ui from "components/ui";
import '../../styles/ui/stripes.scss';
import featureDb from '../../configs/features.js';
import { filter } from 'lodash';

export default (props) => {
  const { features, tags } = props;
  let featureList = [];
  tags.push('all');
  if (tags) {
    featureList = filter(featureDb, (feature) => {
      return tags.some((tag) => feature.tags.includes(tag));
    });
  } else {
    featureList = features;
  }

  return (
    <Ui.Slider items={featureList} />
  )
};

