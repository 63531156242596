import React from "react"

export default [
  {
    title: 'Backup Anomaly Detection',
    text: 'A backup might hide some missing tables or corrupted files, we\'ll detect it!',
    image: (<><i className="fad fa-radar text-primary text-4xl"/></>),
    url: '',
    tags: ['all']
  },
  {
    title: 'Advanced Notifications',
    text: 'Email, Slack, Discord, PushOver, custom webhook notifications for all your backups.',
    image: (<><i className="fad fa-envelope text-primary text-4xl"/></>),
    url: '/features/backup-notifications/',
    tags: ['all']
  },
  {
    title: 'Backup Encryption',
    text: 'Secure your file backups using AES-256 encryption and even using your own key.',
    image: (<><i className="fad fa-binary-lock text-primary text-4xl"/></>),
    url: '/blog/how-to-encrypt-your-backups-using-simplebackups/',
    tags: ['all']
  },
  {
    title: 'Storage SSE-C Support',
    text: 'Implement Server-Side Encryption (SSE-C) when storing your files on your storage.',
    image: (<><i className="fad fa-file-lock text-primary text-4xl"/></>),
    url: '/blog/understanding-implementing-sse-c-cloud-storage/',
    tags: ['all']
  },
  {
    title: 'Incremental Backup',
    text: 'Back up only what matters, optimize your backup file size and only when you’ve made changes.',
    image: (<><i className="fad fa-grip-dots text-primary text-4xl"/></>),
    url: '',
    tags: ['all']
  },
]