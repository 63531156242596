import React from 'react'
import { Link } from "gatsby";
import storageProviders from '../../configs/storage-providers.js';
import * as Ui from "components/ui"
import * as Comp from "components"

const Component = ({subject, className}) => {
  return (
    <ul className={`flex flex-wrap gap-4 max-w-lg ${className}`}>
      {storageProviders.map((provider, index) => (
        <Link to={`/storage/${provider.key}`} key={index}>
          <li><Ui.Popover styled={false} content={`${provider.name} - ${provider.text}`}><Comp.Image filename={`brands/icons/${provider.image}`} alt={`${subject} backup on ${provider.name}`} className="w-7"/></Ui.Popover></li>
      </Link>))}
    </ul>
  )
}

export default Component;