import React, {useState} from 'react'
import * as Ui from "components/ui"
import {filter} from "lodash";
import testimonialDb from "../../configs/testimonials";

const TestimonialSection = (props) => {
  let { testimonials, tags } = props;
  let [limit] = useState(6)

  let items = [];
  if (tags) {
    items = filter(testimonialDb, (testimonial) => {
      return tags.some((tag) => testimonial.tags.includes(tag));
    });
  } else if (testimonials) {
    items = testimonials;
  } else {
    items = testimonialDb;
  }

  let testimonialList = items.slice(0, limit);


  return (
    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <div className="col-md-12 text-center">
            <Ui.Typography tag="h2" className="text-center">What our users are saying</Ui.Typography>

            <div className="relative">
              <div className="grid md:grid-cols-3 gap-8 my-16">
                {testimonialList.map((item, index) => (
                  <Ui.Testimonial
                    avatar={item.avatar}
                    name={item.name}
                    role={item.role}
                    company={item.company}
                    content={item.content}
                  />
                ))}
              </div>
            </div>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>
  )
}

export default TestimonialSection
