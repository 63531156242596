import React from 'react'
import * as Ui from 'components/ui'

const JoinBrandsSection = ({text}) => {
  if (!text) {
    text = "Join developers, startups and businesses using SimpleBackups for all their backups & compliance."
  }
  return (
    <div className="mt-16 md:mt-16 md:mb-8">
      <p className="text-base text-center w-full text-gray-500">{text}</p>
      <div className="grid grid-cols-2 md:grid-cols-6 gap-x-3 gap-y-6 my-8 mb-0 max-w-6xl mx-auto opacity-75">
        <div className="flex justify-center items-center opacity-75">
          <Ui.Image filename="smartair-logo.png" className="max-w-md" alt="Backup solution for startup Smartair"/>
        </div>
        <div className="flex justify-center items-center opacity-75">
          <Ui.Image filename="trackstone-logo.png" alt="Backup solution for startup Trackstone"/>
        </div>
        <div className="flex justify-center items-center opacity-75">
          <Ui.Image filename="sidekick-logo.png" alt="Backup solution for digital agency SideKick"/>
        </div>
        <div className="flex justify-center items-center opacity-75">
          <Ui.Image filename="gumlet-logo.png" alt="Backup solution for developer of Gumletapp"/>
        </div>
        <div className="flex justify-center items-center opacity-75">
          <Ui.Image filename="lsgraphics-logo.png" alt="Agency backup solution"/>
        </div>
        <div className="flex justify-center items-center opacity-75">
          <Ui.Image filename="danet-logo.png" alt="SaaS backup solution"/>
        </div>
      </div>
    </div>
  )
}

export default JoinBrandsSection
