import React from "react"
import * as Ui from "components/ui";
import {Link} from "gatsby";
import {Image} from "../ui";

const GetStartedSection = () => {
  return (
    <Ui.Section className="bg-primary text-white stripe-container text-center">
      <div className="stripe stripe--primary"/>
      <Ui.Container>
        <Ui.Row>
            <Ui.Typography tag="h2" className="text-center text-white">
              Stop worrying about your backups.<br/>
              Focus on building amazing things!
            </Ui.Typography>
            <div className="flex flex-col md:flex-row gap-6 justify-center mt-12 mb-6">
              <Ui.SignupButton color="green" size="large" noSubtitle text="Sign up for free" urlAtrr={{ sb_source: "website", sb_term: "getstarted" }} />
              <Link to={'/pricing'}><Ui.Button color="white" size="large">Discover plans</Ui.Button></Link>
            </div>
            <Ui.Typography tag="p" className="mt-2 text-base opacity-75">Free 7-day trial. No credit card required.</Ui.Typography>
            <p className="my-10 md:my-14 text-base">
              Have a question? Need help getting started?<br/>
              Get in touch via chat or at <a className="font-bold" href="mailto:hello@simplebackups.com">hello@simplebackups.com</a>
            </p>
          <div className="flex flex-col md:flex-row gap-4 md:gap-12 justify-center items-center text-lg">
            <div><i className="far fa-life-ring mr-3"></i><span className="font-bold">Customer support</span> with experts</div>
            <div className="flex items-center">
              <i className="far fa-shield mr-3"></i>
              <span className="font-bold">Security & compliance</span>
              <div className="flex gap-3 ml-4">
                <div className="w-10"><Image filename="iso-27001-ico"></Image></div>
                <div className="w-10"><Image filename="gdpr-ico"></Image></div>
              </div>
            </div>
            <div><i className="far fa-heart mr-3"></i>Service <span className="font-bold">that you'll love using</span></div>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>
  )
}

export default GetStartedSection
