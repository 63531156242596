export default [
  {key: 'amazon-lightsail', name: 'Amazon Lightsail', text: 'Instance and Disk Snapshots', image: 'amazon-lightsail-ico.png', link: ''},
  {key: 'aws-ec2', name: 'AWS EC2', text: 'EC2 Instance & Volume Snapshots', image: 'aws-ico.png', link: ''},
  {key: 'digitalocean', name: 'DigitalOcean', text: 'Droplet & Volume Snapshots', image: 'digitalocean-ico.png', link: ''},
  {key: 'exoscale', name: 'Exoscale', text: 'Server Snapshots', image: 'exoscale-ico.png', link: ''},
  {key: 'hetzner', name: 'Hetzner', text: 'Cloud VPS Snapshots', image: 'hetzner-ico.png', link: ''},
  {key: 'scaleway', name: 'Scaleway', text: 'Server & Volume Snapshots', image: 'scaleway-ico.png', link: ''},
  {key: 'upcloud', name: 'UpCloud', text: 'Storage Snapshots', image: 'upcloud-ico.png', link: ''},
  {key: 'vultr', name: 'Vultr', text: 'Cloud Compute Snapshots', image: 'vultr-ico.png', link: ''},
  {key: 'linode-akamai', name: 'Linode (Akamai)', text: 'Instance Snapshots', image: 'linode-ico.png', link: ''},
  {key: 'outscale', name: 'Outscale', text: 'Instance & Volume Snapshots', image: 'outscale-ico.png', link: ''},
  {key: 'ovh', name: 'Ovh', text: 'Instance & Volume Snapshots', image: 'ovh-ico.png', link: ''},
]