import React from 'react'
import { FeatureGrid, Feature, }  from 'components/ui'

export default () => (
  <FeatureGrid cols="3" className="mt-8">
    <Feature
      icon={<i className="far fa-terminal" />}
      title="One command setup"
      content="Add your servers with one single command line. No need to install any dependencies, set up cron, or edit any config files."
      boxed
    />
    <Feature
      icon={<i className="far fa-cloud" />}
      title="Bring your own storage"
      content="Securely store your backups on your own DigitalOcean, AWS, Backblaze, Google, Wasabi, Filebase, Dropbox ...
      Your backups won't even pass by our servers."
      boxed
    />
    <Feature
      icon={<i className="far fa-rabbit-fast" />}
      title="Recover quickly"
      content="Find and restore the backup you need quickly. Restore database and file backups independently."
      boxed
    />
  </FeatureGrid>
)