import React from "react"
import * as Ui from "components/ui";

const FaqSection = ({faqs, terms, color}) => {
  return (
    <Ui.Section color={color}>
      <Ui.Container id="faq">
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center">Frequently Asked Questions</Ui.Typography>
          <Ui.Faqs ids={faqs} terms={terms} />
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>
  )
}

export default FaqSection
