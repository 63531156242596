import React from 'react'
import {Link} from 'gatsby'
import Image from "components/image"


const types = [
  {key: 'server', name: 'Server Backup', image: 'server-p-icon.png', link: '/server-backup/'},
  {key: 'mysql', name: 'MySQL Backup', image: 'mysql-ico.png', link: '/mysql-backup/'},
  {key: 'postgresql', name: 'PostgreSQL Backup', image: 'postgresql-ico.png', link: '/postgresql-backup/'},
  {key: 'mongodb', name: 'MongoDB Backup', image: 'mongodb-ico.png', link: '/mongodb-backup/'},
  {key: 'redis', name: 'Redis Backup', image: 'redis-ico.png', link: '/redis-backup/'},
  {key: 'storage', name: 'Storage Backup', image: 'storage-p-icon.png', link: '/storage-backup/'},
  {key: 'saas', name: 'SaaS App Backup', image: 'saas-p-icon.png', link: '/saas-backup/'},
]
const BackupTypes = () => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-5 text-left md:max-w-4xl m-auto">
      {types.map(provider => (
        <Link to={`${provider.link}`} className="flex justify-between flex-col bg-white border-indigo-100 border rounded-3xl py-8 px-4 text-sm text-center">
          <div className="flex items-center relative justify-center mb-3">
            <Image filename={provider.image} alt={`${provider} backup`} className="w-12" />
          </div>
          <div>
            <p className="font-bold mb-1">{provider.name}</p>
          </div>
        </Link>
      ))}
    </div>
  )
}

export default BackupTypes