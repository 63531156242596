import React, {useState} from 'react'
import * as Ui from "components/ui"
import {filter} from "lodash";
import testimonialDb from "../../configs/testimonials";

const TestimonialSection = (props) => {
  let { testimonials, tags } = props;
  let [limit, setLimit] = useState(6)
  const limitIncrement = 3;

  let items = [];
  if (tags) {
    items = filter(testimonialDb, (testimonial) => {
      // check if testimonials.tags is defined and includes at least one element of tags
      return testimonial.tags && testimonial.tags.some(tag => tags.includes(tag));
    });
  } else if (testimonials) {
    items = testimonials;
  } else {
    items = testimonialDb;
  }

  let testimonialList = items.slice(0, limit);


  return (
    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <div className="col-md-12 text-center">
            <Ui.Typography tag="h2" className="text-center">What our users are saying</Ui.Typography>

            <div className="relative">
              <div className="grid md:grid-cols-3 gap-8 my-16">
                {testimonialList.map((item, index) => (
                  <Ui.Testimonial
                    avatar={item.avatar}
                    name={item.name}
                    role={item.role}
                    company={item.company}
                    content={item.content}
                  />
                ))}
              </div>
              { items.length > testimonialList.length &&
                <div>
                  <Ui.Button color="primary-200" size="large" className="relative z-10 shadow-xl cursor-pointer" onClick={() => setLimit(limit+limitIncrement)}>Show More</Ui.Button>
                  <div className="absolute bottom-0 bg-gradient-to-t from-white via-white to-transparent w-full block h-80"></div>
                </div>
              }
            </div>
          </div>
          <div className="text-center flex justify-center"><Ui.GtwoBadge/><Ui.CapterraBadge/></div>
          <p className="text-center"><Ui.Link color="primary" to="/case-study/" arrow="right">Discover how our users backup their data</Ui.Link></p>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>
  )
}

export default TestimonialSection
