import React from 'react'
import { Link } from "gatsby";
import providers from '../../configs/snapshot-providers.js';
import * as Ui from "components/ui"
import * as Comp from "components"

const Component = ({subject, className}) => {
  return (
    <ul className={`flex flex-wrap gap-4 max-w-md ${className}`}>
      {providers.map((provider, index) => (
          <li>
            <Link to={`/snapshots/${provider.key}`} key={index}>
              <Ui.Popover styled={false} content={`${provider.name} ${provider.text}`}><Comp.Image filename={`brands/icons/${provider.image}`} alt={`${subject} backup on ${provider.name}`} className="w-7"/></Ui.Popover>
            </Link>
          </li>
        ))}
    </ul>
  )
}

export default Component;