import React from 'react'
import * as Ui from "components/ui";
import '../../styles/ui/stripes.scss';
import articleDb from '../../configs/learn.js';
import { filter } from 'lodash';

export default (props) => {
  const { title, text, articles, tags } = props;
  let articleList = [];
  if (tags) {
    articleList = filter(articleDb, (article) => {
      return tags.some((tag) => article.tags.includes(tag));
    });
  } else {
    articleList = articles;
  }

  return (
    <Ui.Section className="bg-aubergine stripe-container text-white text-center">
      <div className="stripe stripe--aubergine"/>
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center text-white">{title}</Ui.Typography>
          <div className="mt-16 flex flex-col md:flex-row gap-6 md:gap-12 justify-center">
            <a href="https://docs.simplebackups.com" className="md:w-1/5 py-6 md:py-12 border border-primary rounded-xl bg-aubergine hover:-translate-y-1 hover:shadow-2xl transform transition">
              <span className="block text-sm">Discover our</span>
              <span className="block text-xl font-medium ">Documentation</span>
            </a>
            <a href="https://simplebackups.docs.apiary.io/" className="md:w-1/5 py-6 md:py-12 border border-primary rounded-xl bg-aubergine hover:-translate-y-1 hover:shadow-2xl transform transition">
              <span className="block text-sm">Discover our</span>
              <span className="block text-xl font-medium ">API</span>
            </a>
            <a href="/catalog/" className="md:w-1/5 py-6 md:py-12 border border-primary rounded-xl bg-aubergine hover:-translate-y-1 hover:shadow-2xl transform transition">
              <span className="block text-sm">Discover our</span>
              <span className="block text-xl font-medium ">Integrations</span>
            </a>
          </div>
          { text && (<p className="pt-16 pb-8 text-base">{text}</p>)}
          { articleList && (
            <div className="gap-x-6 gap-y-2 justify-center grid md:grid-cols-2 m-auto text-base">
            {articleList.map((link, index) => (
              <a href={link.url} className="border border-primary  rounded-lg block text-left px-4 py-3 bg-aubergine group hover:-translate-y-1 hover:shadow-2xl transform transition">
                <i className="fa fa-angle-right text-sm mr-5 text-primary group-hover:translate-x-1 transition"></i>{link.text}
              </a>))}
          </div>)}

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>
  )
};

