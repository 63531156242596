import React from 'react'
import * as Ui from "components/ui";
import '../../styles/ui/stripes.scss';
import articleDb from '../../configs/learn.js';
import { filter } from 'lodash';

export default (props) => {
  const { articles, tags } = props;
  let articleList = [];
  if (tags) {
    articleList = filter(articleDb, (article) => {
      return tags.some((tag) => article.tags.includes(tag));
    });
  } else {
    articleList = articles;
  }

  return (
    <>
      {articleList &&articleList.map((link) => (
        <p><Ui.Link to={link.url} underline={false}>
          {link.text}
        </Ui.Link></p>))}
    </>
  )
};

